// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { NavigationService } from '../../ag-shared/services/navigation.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn : "root"
})
export class RedirectService {
    histories: string[] = [];
    pageRefreshSubscription: Subject<string> = new Subject<string>();
    unstorableUrls : string[] = [
      'create',
      'edit',
      'checkout',
      'cubelab/request',
      'auth/',
      'p/gr'
    ];

    constructor(
        private router: Router,
        private _location: Location,
        private navigationService : NavigationService,
        private storage : LocalStorageService
    ) {
        this.histories = this.storage.getItem('navigated_urls') || [];
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let currentUrl = event.urlAfterRedirects;
                if(this.histories && this.histories.length > 0 && currentUrl != this.histories[this.histories.length - 1]) {
                    if(this.histories.length == 10) {
                        this.histories.splice(0, 1);
                    }
                    this.histories.push(event.urlAfterRedirects);
                    this.storage.setItem('navigated_urls', this.histories);
                } else if(!this.histories || this.histories.length == 0) {
                    this.histories.push(event.urlAfterRedirects);
                    this.storage.setItem('navigated_urls', this.histories);
                }
            }
        });
    }

    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
            this.pageRefreshSubscription.next(currentUrl);
        });
    }    

    goPreview() {
        this.router.navigate(['/dashboard']);
    }

    goHome() {
        this.router.navigate(['/welcome']);
    }

    getCurrentPath() {
        return this._location.path(true);
    }

    getCurrentRoute() {
        return this.router.url;
    }

    goUrl(url, queryParams?) {
        if (queryParams) {
            this.router.navigate([url], queryParams);
        } else {
            this.router.navigate([url]);
        }
    }

    goBack() {
        this.histories = this.storage.getItem('navigated_urls') || [];
        if(this.histories && this.histories.length > 0) {
            console.log('histories---------------------------->', this.histories);
            // console.log('history---------------------------->', this.histories[this.histories.length - 1]);
            let someCheck = this.unstorableUrls.some(d => { return this.histories[this.histories.length - 1].includes(d) });
            // console.log('someCheck---------------------------->', someCheck);
            let indexHistory = this.histories[this.histories.length - 1];
            if(someCheck) {
                indexHistory = this.histories[this.histories.length - 2]
            }
            // console.log('indexHistory---------------------------->', indexHistory);
            // let urlIndex = this.histories.indexOf(indexHistory);
            let urlIndex = this.histories.findIndex(d => d && d.split('?')[0] == indexHistory);
            // console.log('urlIndex---------------------------->', urlIndex);
            if(urlIndex != -1 && urlIndex != this.histories.length - 1) {
                if(someCheck) {
                    urlIndex += 1;
                }
                this.histories.splice(urlIndex, this.histories.length - urlIndex);
            } else if(this.router.url == indexHistory) {
                this.histories.pop();
            }
            // console.log('histories---------------------------->', this.histories);
            let history = this.histories[this.histories.length - 1] || '';
            // console.log('history---------------------------->', history);
            // let nextSomeCheck = this.unstorableUrls.some(d => { return history.includes(d) });
            let position = this.checkHistory(history || '', 1);
            // console.log('position---------------------------->', position);
            if(position) {
                history = this.histories[this.histories.length - position] || ''
            }
            // console.log('history---------------------------->', history);
            if(history) {
                this.histories.pop();
                this.storage.setItem('navigated_urls', this.histories);
                // this.router.navigate([history]);
                this.router.navigateByUrl(history);
            } else {
                this.router.navigate(['/menu']);
            }
        } else {
            this.router.navigate(['/menu']);
        }
    }

    checkHistory(history, position : number = 1) {
        console.log('checkHistory---------------------------->', history);
        console.log('position---------------------------->', position);
        let nextSomeCheck = this.unstorableUrls.some(d => { return history.includes(d) });
        console.log('nextSomeCheck---------------------------->', nextSomeCheck);
        if(nextSomeCheck) {
            position += 1;
            history = this.histories[this.histories.length - position] || '';
            return this.checkHistory(history, position);
        } else {
            return position || '';
        }
    }

    goEmployeeAdmin(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/super-admin/employee-admin/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/super-admin/employee-admin/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/super-admin/employee-admin/view', id]);
        } else {
            this.router.navigate(['/super-admin/employee-admin/list']);
        }
    }

    goEmployee(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/employees/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/employees/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/employees/view', id]);
        } else {
            this.router.navigate(['/employees/list']);
        }
    }

    goRole(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/roles/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/roles/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/roles/view', id]);
        } else {
            this.router.navigate(['/roles/list']);
        }
    }

    goLookup(type, groupId?, id?) {
        if (type == 'create' && groupId) {
            this.router.navigate(['/lookup/create', groupId]);
        } else if (type == 'edit' && groupId && id) {
            this.router.navigate(['/lookup/edit', groupId, id]);
        } else if (type == 'view' && groupId) {
            this.router.navigate(['/lookup/view', groupId]);
        } else {
            this.router.navigate(['/lookup/list']);
        }
    }

    goAccounts(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/super-admin/accounts/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/super-admin/accounts/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/super-admin/accounts/view', id]);
        } else {
            this.router.navigate(['/super-admin/accounts/list']);
        }
    }

    goAccountModuleMapping(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/super-admin/account-module-mappings/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/super-admin/account-module-mappings/edit/', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/super-admin/account-module-mappings/view', id]);
        } else {
            this.router.navigate(['/super-admin/account-module-mappings/list']);
        }
    }

    goChapter(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/chapter/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/chapter/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/chapter/view', id]);
        } else {
            this.router.navigate(['/chapter/list']);
        }
    }

    goZone(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/zone/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/zone/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/zone/view', id]);
        } else {
            this.router.navigate(['/zone/list']);
        }
    }

    goMemberShipType(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/membership-type/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/membership-type/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/membership-type/view', id]);
        } else {
            this.router.navigate(['/membership-type/list']);
        }
    }

    goSubscriptionPlan(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/subscription-plan/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/subscription-plan/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/subscription-plan/view', id]);
        } else {
            this.router.navigate(['/subscription-plan/list']);
        }
    }
   
    goTaxGroups(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/tax-group/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/tax-group/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/tax-group/view', id]);
        } else {
            this.router.navigate(['/tax-group/list']);
        }
    }

    goLead(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/lead/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/lead/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/lead/view', id]);
        } else {
            this.router.navigate(['/lead/list']);
        }
    }

    goMembers(type, id?) {
        if (type == 'create' && id) {
            this.router.navigate(['/member/create', id]);
        } else if (type == 'create') {
            this.router.navigate(['/member/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/member/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/member/view', id]);
        } else if(type == 'directory'){
            this.router.navigate(['/member/directory']);
        }
        else {
            this.router.navigate(['/member/list']);
        }
    }

    goMemberSubscriptions(type, id?, queryParams?) {
        if (type == 'create') {
            this.router.navigate(['/member-subscription/create'], {queryParams : queryParams});
        } else if (type == 'edit' && id) {
            this.router.navigate(['/member-subscription/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/member-subscription/view', id]);
        } else if (type == 'all-compare') {
            this.router.navigate(['/member-subscription/all-compare']);
        } else if (type == 'compare') {
            this.router.navigate(['/member-subscription/compare']);
        } else {
            this.router.navigate(['/member-subscription/list'], {queryParams : queryParams});
        }
    }

    goSpeakers(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/speaker/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/speaker/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/speaker/view', id]);
        } else {
            this.router.navigate(['/speaker/list']);
        }
    }

    goReferrals(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/referrals/create']);
        } else {
            this.router.navigate(['/referrals/list']);
        }
    }

    goTeams(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/teams/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/teams/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/teams/view', id]);
        } else {
            this.router.navigate(['/teams/list']);
        }
    }

    goCubeLabs(type, id?, ids?) {
        if (type == 'create') {
            this.router.navigate(['/cubelabs/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/cubelabs/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/cubelabs/view', id]);
        } else if (type == 'meeting' && id) {
            this.router.navigate(['/cubelabs/meeting' , id, ids]);
        } else if (type == 'member-view' && id) {
            this.router.navigate(['/cubelabs/member-view', id]);
        } else if (type == 'request'){
            this.router.navigate(['/cubelabs/request']);
        }
        else {
            this.router.navigate(['/cubelabs/list']);
        }
    }

    goEventType(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/event-type/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/event-type/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/event-type/view', id]);
        } else {
            this.router.navigate(['/event-type/list']);
        }
    }

    goEvent(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/spotlights/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/spotlights/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/spotlights/view', id]);
        } else {
            this.router.navigate(['/spotlights/list']);
        }
    }

    goEventV1(type, id?, queryParams?){
        if (type == 'create') {
            this.router.navigate(['/eventV1/create'], {queryParams : queryParams});
        } else if (type == 'create-member-attendance') {
            this.router.navigate(['/eventV1/create-member-attendance']);
        } else if (type == 'create-other-chapter-attendance') {
            this.router.navigate(['/eventV1/create-other-chapter-attendance']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/eventV1/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/eventV1/view', id]);
        } else if (type == 'rating' && id) {
            this.router.navigate(['/eventV1/rating', id]);
        } else {
            this.router.navigate(['/eventV1/list']);
        }
    }
    
    goOfficeBearers() {
        this.router.navigate(['/office-bearers/list']);
    }

    goCubeLabAdmin(type, id?, ids?) {
        if (type == 'create') {
            this.router.navigate(['/cubelab-admin/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/cubelab-admin/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/cubelab-admin/view', id]);
        } else if(type == 'meeting-view' && id && ids){
            this.router.navigate(['/cubelab-admin/meeting-view', id, ids])
        } else {
            this.router.navigate(['/cubelab-admin/list']);
        }
    }

    goMeetings(type, id?) {
        if(type == 'view' && id) {
            this.router.navigate(['/meeting/view', id]);
        } else {
            this.router.navigate(['/meeting/list']);
        }
    }
    goReport(type, id?) {
        if (type == 'attendance-report') {
            this.router.navigate(['/reports/attendance-report']);
        }
    }

    goSponsor(type, id?) {
        if (type == 'create') {
            this.router.navigate(['/sponsor/create']);
        } else if (type == 'edit' && id) {
            this.router.navigate(['/sponsor/edit', id]);
        } else if (type == 'view' && id) {
            this.router.navigate(['/sponsor/view', id]);
        }
        else {
            this.router.navigate(['/sponsor/list']);
        }
    }
}