export const environment = {
    production: true,
    env : 'prod',
    appVersion: '2.0.93',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: true,
    apiUrl: 'https://yeslbapi.yesconnect.in',
    API_BASE_URL: 'https://yeslbapi.yesconnect.in/api/v1',
    REST_API_BASE_URL: 'https://yeslbapi.yesconnect.in/restapi',
    AWS_ACCESSKEY : 'AKIASFUIR5KBM4RPVQ22',
    AWS_SECRETKEY : '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',
    AWS_S3_DMS_REGION : 'ap-south-1',
    AWS_S3_DMS_BUCKET : 'dms-yca',
    GSP_API_BASE_URL: 'https://yns.yesconnect.in/notificationService/v1',
    firebaseConfig: {
        apiKey: "AIzaSyBfzxsF25Ne8QcUI9KdM_ZSqB5MWBuLp6w",
      authDomain: "yesconnect-app.firebaseapp.com",
      projectId: "yesconnect-app",
      storageBucket: "yesconnect-app.appspot.com",
      messagingSenderId: "906869187321",
      appId: "1:906869187321:web:b2777d4a85b936028ff828"

    },
    fcm_vapid_key: 'BDk677pOR0ep0vwoOQ5o805x15W-fJ_f7IT5Zoh2DjPpXIawXwGt4mXdGSPIt3itCmR9ZSoEVukShsMqLxVeYVo',
    MEASUREMENT_ID: 'G-LW4SF1LMDZ',
    allow_console_log: false
};